import { Link } from "@StarberryUtils";
import * as React from "react"
//import ScrollAnimation from "react-animate-on-scroll";
import { Button, Container, Row, Col, Form } from "react-bootstrap"
import deskbanner from "../../../images/offplan/desk-banner.png";
import "./RegisterInterestForm.scss"
import RegiterFormPage from "../../forms/register-form";

// markup
const RegisterInterestForm = () => {
    return (
        <React.Fragment>
            <section className="register-interest-form-wrapper" id="register">
                <Container>
                    <Row className="main-row">
                        <Col lg="12">
                        <div className="register-interest-form-block">    
                            <div className="register-interest-form-content">
                                <div animateIn="fadeInUp" animateOnce>
                                <h2>Register your interest</h2>
                                </div>
                                {/* <div animateIn="fadeInUp">
                                <p>Nam ut risus vel metus porta posuere. Praesent nec tempus nisl. Nunc fringilla odio vitae lorem sagittis, vitae bibendum tortor molestie supendisse.</p>
                                </div> */}
                            </div>
                            <div className="register-interest-form">
                            <div animateIn="fadeInUp">
                               <RegiterFormPage />
                                </div>

                            </div>
                        </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </React.Fragment>
    )
}

export default RegisterInterestForm