import { Link } from "@StarberryUtils";
import * as React from "react"
//import ScrollAnimation from "react-animate-on-scroll";
import { Button, Container, Row, Col } from "react-bootstrap"
import moment from "moment";
import HTMLReactParser from 'html-react-parser';

import "./Opportunity.scss"
// markup
const Opportunity = (props) => {
    let property = props.data;
    return (
        <React.Fragment>
            <section className="exclusive-opportunity" id="exclusive-opportunity">
                <div animateIn='fadeInUp' animateOnce>
                    <Container>
                        <Row>
                            <Col>
                                <div className="about-opportunity">
                                    <p>{property.Details_Page_Intro}</p>
                                </div>
                                <div className="content-wrapper">
                                    <div className="content">
                                        <span className="sub-title">Location:</span>
                                        <span className="sm-text">
                                            {property.Location}
                                        </span>
                                    </div>
                                    <div className="content developer-content order-3">
                                        <span className="sub-title">Developer:</span>
                                        <span className="sm-text">
                                            {property.Developer}
                                        </span>
                                    </div>
                                    <div className="content order-5">
                                        <span className="sub-title">Development Type:</span>
                                        <span className="sm-text">
                                            {property.Developer_Type}
                                        </span>
                                    </div>

                                    <div className="content">
                                        <span className="sub-title">Completion Date:</span>
                                        <span className="sm-text">
                                            {moment(property.Completion_Date).format("MMMM YYYY")}

                                        </span>
                                    </div>
                                    <div className="content order-4">
                                        <span className="sub-title">Prices from:</span>
                                        <span className="sm-text">
                                            AED {property.Price.toLocaleString()}
                                        </span>
                                    </div>

                                </div>
                                <div className="content-wrapper">
                                    <div className="description">
                                        {HTMLReactParser(property.Details_Page_Description.replace(/<br\s*\/?>/gi, ''))}
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </section>
        </React.Fragment>
    )
}

export default Opportunity