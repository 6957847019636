import { Link } from "@StarberryUtils";
import * as React from "react"
//import ScrollAnimation from "react-animate-on-scroll";
import { Button, Container, Row, Col } from "react-bootstrap"
import gallery01 from "../../../images/offplan/gallery01.png";
import gallery02 from "../../../images/offplan/gallery02.png"
import gallery03 from "../../../images/offplan/gallery03.png"
import Map from "../../map/google/single"

import Slider from "react-slick";

import "./Location.scss"
// markup
const Location = (props) => {
    let property = props.data; 
    return (
        <React.Fragment>
            <section className="offpage-location" id="location">
                <div animateIn='fadeInUp' animateOnce>

                    <Container>
                        <h3>Location</h3>
                        <Row>
                            <Col className="property-map"> 
                            <Map lat={property.Latitude ? parseFloat(property.Latitude) : ''} lng={property.Longtitude ? parseFloat(property.Longtitude) : ''} height={"600px"} zoom={14}/>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </section>
        </React.Fragment>
    )
}

export default Location