import { Link } from "@StarberryUtils";
import * as React from "react"
//import ScrollAnimation from "react-animate-on-scroll";
import { Button, Container, Row, Col } from "react-bootstrap"
import moment from "moment";
import HTMLReactParser from 'html-react-parser';

import "./KeyFeature.scss"
// markup
const KeyFeature = (props) => {
    let property = props.data;
    return (
        <React.Fragment>
            <section className="key-feature">
                <div animateIn='fadeInUp' animateOnce>
                    <Container>
                        <Row>
                            <Col>
                                <div className="title">
                                    <h3>Key Features & Amenities</h3>
                                </div> 
                                <div className="feature-list"> 
                                        {property?.Key_Features &&
                                            HTMLReactParser(property?.Key_Features?.replace(/\n/g, '<br />'))
                                        }
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </section>
        </React.Fragment>
    )
}

export default KeyFeature