import { Link } from "@StarberryUtils";
import React,{useEffect, useState} from "react";
import { saveAs } from 'file-saver';
//import ScrollAnimation from "react-animate-on-scroll";
import { Container, Row, Col } from "react-bootstrap"
import "./DetailsMenu.scss"
import $ from "jquery";
// markup
const DetailsMenu = (props) => {
    let property = props.data;

    const downloadBrochure = (url) => {
        saveAs(url, "Brochure.pdf");
    }

    const [scroll, setScroll] = useState(false);

    useEffect(() => {
        // New home click to scroll
        $(".details").click(function() {
            $('html, body').animate({
                scrollTop: $("#exclusive-opportunity").offset().top - 150
            }, 1000);
        });
        $(".details_gallery").click(function() {
            $('html, body').animate({
                scrollTop: $("#gallery").offset().top - 150
            }, 1000);
        });
        $(".details_location").click(function() {
            $('html, body').animate({
                scrollTop: $("#location").offset().top - 150
            }, 1000);
        });
        $(".details_payment").click(function() {
            $('html, body').animate({
                scrollTop: $("#payment").offset().top - 150
            }, 1000);
        });

        window.addEventListener("scroll", () => {
            setScroll(window.scrollY > 60)
        })
    }, []);
   
    return (
        <React.Fragment>
            <section className={scroll ? "details-menu details-fixed-scroll" : "details-menu"} id="off-plan-details">
                <Container>
                    <Row>
                        <Col>
                            <ul className="menu-list d-none d-lg-flex">
                                <li> 
                                    <a href="javascript:void(0)" className="details"> Details  </a>
                                </li>
                                {property.Upload_Brochure &&
                                    <li>
                                        <a href="javascript:void(0)" onClick={() => { downloadBrochure(`${property.Upload_Brochure.url}`) }}>  Brochure </a>
                                    </li>
                                }
                                {property.Gallery &&
                                    <li>
                                        <a href="javascript:void(0)" className="details_gallery"> Gallery  </a>
                                    </li>
                                }
                                {property.Latitude &&
                                    <li>
                                        <a href="javascript:void(0)"  className="details_location"> Location  </a>
                                    </li>
                                }
                                {property.Add_Payment_Plan &&
                                    <li>
                                        <a href="javascript:void(0)"  className="details_payment" >   Payment Plan   </a>
                                    </li>
                                }
                            </ul>
                            <div className="d-lg-none scroll_h">
                                <ul className="menu-list justify-content-start">
                                    <li>
                                    <a href="javascript:void(0)" className="details"> Details  </a>
                                    </li>
                                    {property.Upload_Brochure &&
                                        <li>
                                            <a href="javascript:void(0)"  onClick={() => { downloadBrochure(`${property.Upload_Brochure.url}`) }}>  Brochure </a>
                                        </li>
                                    }
                                    {property.Gallery &&
                                        <li>
                                            <a href="javascript:void(0)" className="details_gallery"> Gallery  </a>
                                        </li>
                                    }
                                    {property.Latitude &&
                                        <li>
                                             <a href="javascript:void(0)"  className="details_location"> Location  </a>
                                        </li>
                                    }
                                    {property.Add_Payment_Plan &&
                                        <li>
                                             <a href="javascript:void(0)"  className="details_payment" >   Payment Plan   </a>
                                        </li>
                                    }
                                </ul>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </React.Fragment>
    )
}

export default DetailsMenu