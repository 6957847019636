import { Link } from "@StarberryUtils";
import React,{useEffect, useState} from "react";
//import ScrollAnimation from "react-animate-on-scroll";
import { saveAs } from 'file-saver';
import $ from 'jquery'
import { Button, Container, Row, Col } from "react-bootstrap"
import PlayVideo from "../../Play/custom-video";
import ImageTransform from "../../common/ggfx-client/module/components/image-transform";
import mobbanner from "../../../images/offplan/mob-banner.png";
import tabbanner from "../../../images/offplan/tab-banner.png";
import deskbanner from "../../../images/offplan/desk-banner.png";
import "./OffPlanBanner.scss"
// markup
const OffDetails = (props) => {
    let [isPlay, setPlay] = React.useState(false);
    let property = props.data;

    const downloadBrochure = (url) => {
        saveAs(url, "Brochure.pdf");
    }


    useEffect(() => {
        // New home click to scroll
        $(".details_features").click(function() {
            $('html, body').animate({
                scrollTop: $("#register").offset().top - 10
            }, 1000);
        });
    }, []);


    let processedImages = JSON.stringify({});
    if (property.imagetransforms?.Banner_Image_Transforms) {
        processedImages = property.imagetransforms.Banner_Image_Transforms;
    }

    return (
        <React.Fragment>
            <section className="off-details-banner" id="off-details-banner">
                    <div className="off-banner">
                        {property.Banner_Image &&
                            <ImageTransform imagesources={property.Banner_Image.url} renderer="srcSet" imagename="off-plan-properties.Banner_Image.details" attr={{ alt: 'Off Plan banner - Dacha', class:'' }} imagetransformresult={processedImages} id={property.id}/>
                        }

                        <div className="banner-wrapper">
                            {property.Video_URL &&
                                <div className="banner-btn">
                                    <PlayVideo url={property.Video_URL}
                                    />
                                </div>
                            }
                            <div className="banner-content">
                                <h1>{property.Title}</h1>
                                <span className="sm-text">Prices from: AED {property.Price.toLocaleString()}</span>
                            </div>
                            <div className="banner-btn d-none d-xl-flex">
                                <button className="btn btn-primary details_features">
                                    Register Your Interest
                                </button>
                                {property.Upload_Brochure &&
                                    <button className="btn btn-secondary" onClick={() => { downloadBrochure(`${property.Upload_Brochure.url}`) }} >
                                        Download Brochure
                                     </button>
                                }
                            </div>
                        </div>
                    </div>
            </section>
        </React.Fragment>
    )
}

export default OffDetails