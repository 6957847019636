import { Link } from "@StarberryUtils";
import * as React from "react"
//import ScrollAnimation from "react-animate-on-scroll";
import { Button, Container, Row, Col } from "react-bootstrap"
import gallery01 from "../../../images/offplan/gallery01.png";
import gallery02 from "../../../images/offplan/gallery02.png"
import gallery03 from "../../../images/offplan/gallery03.png"
import ImageTransform from "../../common/ggfx-client/module/components/image-transform";
import Slider from "react-slick";

import "./Gallery.scss"
// markup
const Gallery = (props) => {
    let property = props.data;
    const settings = {
        dots: false,
lazyLoad: true,
        speed: 800,
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: false,
        arrows: true,
        mobileFirst: true,
        responsive: [

            {
                breakpoint: 1199,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1 
                },
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1 

                },
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1 
                },
            },
        ],
    }

    let processedImages = JSON.stringify({});
    if (property.imagetransforms?.Banner_Image_Transforms) {
        processedImages = property.imagetransforms.Banner_Image_Transforms;
    }
   
    return (
        <React.Fragment>
            <section className="offpage-gallery"  id="gallery">
                <div animateIn='fadeInUp' animateOnce>

                    <Container>
                        <h3>Gallery</h3>
                        <Row>
                            <Col>
                                <Slider  {...settings}> 
                                  { property.Gallery && property.Gallery.map((image, i) => {
                                   return (<div className="gallery-img"> 
                                        <ImageTransform imagesources={image.url} renderer="srcSet" imagename="off-plan-properties.Banner_Image.details" attr={{ alt: 'Off Plan Gallery - Dacha', class:'' }} imagetransformresult={processedImages} id={property.id}/>
                                    </div> 
                                   )
                                  })}
                                </Slider>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </section>
        </React.Fragment>
    )
}

export default Gallery